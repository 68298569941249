(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
(function($) {
	"use strict";

	var preloader = require("./modules/infinite_preloader.js"),
		helpers = require("./modules/helpers.js"),
		map = require("./modules/gmap.js"),
		animations = require("./modules/animations.js"),
		screenEvents = require("./modules/screens.js"),
		productsGallery = require("./modules/products-gallery.js"),
		tabs = require("./modules/tabs.js"),
		bbslider = require("./modules/bbslider.js"),
		ux = require("./modules/ux.js");


	// ==============================
	// Init standalone modules
	// ==============================
	animations.animateCss_init();
	// screenEvents.init(".bb-change");

	// ==============================
	// Footer scroll button
	// ==============================
	$("#go-up").click(function(){
		$("html, body").stop().animate({
			scrollTop: 0
		}, 700, "swing");
	});


	// ==============================
	// Animations
	// ==============================
	animations.fadePageOn("a.preload-link", "#preloader", 200);

	$.fn.animated = animations.animateCss;
	$(".animated-test-container").animated("slideInRight");

	// Add scrollbars
	if($(".ig-scroll").exists()){
		$(".ig-scroll").mCustomScrollbar({
			theme : "dark-thick",
			axis : "y"
		});
	}


	// ==============================
	// Ajust heights
	// ==============================
	function ajustHeights(){
		helpers.ajustHeights(".album-category");
		helpers.ajustHeights(".album-item__footer");
	}

	$(window).on("load", ajustHeights);
	$(window).resize(ajustHeights);


	// ==============================
	// Services
	// ==============================
	(function () {
		if($("#services").exists()){
			var controls = "#services-controls li",
				items = "#services-items .services__item";

			if($("#services .screen__title").exists()){
				var services_title = $("#services .screen__title");
				
				$(controls).on("click", function(){
					services_title.css({
						"transition-delay" : "0s",
						"opacity" : 0,
						"visibility" : "hidden"
					});
				});
			}
			tabs(controls, items);
		}
	})();

	// ==============================
	// About
	// ==============================
	(function () {
		if($("#about").exists()){
			var controls = "#about-controls li",
				items = "#about-items .services__item";

			if($("#about .screen__title").exists()){
				var services_title = $("#about .screen__title");
				
				$(controls).on("click", function(){
					services_title.css({
						"transition-delay" : "0s",
						"opacity" : 0,
						"visibility" : "hidden"
					});
				});
			}
			tabs(controls, items);
		}
	})();


	// ==============================
	// Google map init
	// ==============================
	if($("#map").exists()){
		var map_selector = "#map",
			icon_template = "#marker-icon";

		map.init(map_selector,icon_template);
	}

	// ==============================
	// Preloader
	// ==============================
	preloader().done(function(){
		ux.init();
	});

})(jQuery);
},{"./modules/animations.js":2,"./modules/bbslider.js":3,"./modules/gmap.js":4,"./modules/helpers.js":5,"./modules/infinite_preloader.js":6,"./modules/products-gallery.js":7,"./modules/screens.js":8,"./modules/tabs.js":9,"./modules/ux.js":10}],2:[function(require,module,exports){
// ===================================================
// Animate elements on scroll. Based on waypoints.js
// ===================================================
var animateCss_scroll = function (in_effect) {
	$(this).each(function() {
		var elem = $(this);
		elem.css({opacity:0})
			.addClass("animated")
			.waypoint(function(dir) {
				if (dir === "down") {
					elem.addClass(in_effect).css({opacity:1});
				}
			},
			{
				offset: "90%"
			});
	});
};



var animateCss_instant = function (in_effect) {
	$(this).each(function() {
		var elem = $(this);
		elem.addClass(in_effect)
			.css({opacity:1});
	});

	return this;
};


// ===================================================
// Init Animate CSS as chain function
// ===================================================
function animateCss_init(){
	$.fn.animate_scroll = animateCss_scroll;
	$.fn.animate_now = animateCss_instant;
}



// ========================================================
// Show owerlay layer when specified link has been clicked
// ========================================================
function fadePageOn (link_selector, overlay_selector, time){
	$(document).on("click", link_selector, function(e) {
		var href = $(this).attr("href");
		if(href[0]!="#"){
			e.preventDefault();

			return $(overlay_selector)
				.addClass("loading")
				.fadeIn(time, function(){
					return document.location = href != null ? href : "/";
				});
		}
	});
}

module.exports = {
	animateCss : animateCss_scroll,
	fadePageOn : fadePageOn,
	animateCss_init : animateCss_init
};
},{}],3:[function(require,module,exports){
function init(slider_obj){
	var next_button = slider_obj.find(".bb-slider__next"),
		prev_button = slider_obj.find(".bb-slider__prev"),
		slides = slider_obj.find(".bb-slider__slides .slide"),
		active_slide = slides.filter('.active'),
		slides_count = slides.length,
		index = slides.index(active_slide);


	function showSlide(i){
		slides.eq(i)
			.addClass("active")
			.siblings()
			.removeClass("active");
	}

	next_button.on("click", function(){
		index++;
		if(index>=slides_count){
			index = 0;
		}
		showSlide(index);
	});

	prev_button.on("click", function(){
		index--;
		if (index<0) {
			index = slides_count-1;
		}
		showSlide(index);
	});
}


module.exports = {
	init : init
};
},{}],4:[function(require,module,exports){
var map_styles = [
	{
		"featureType": "water",
		"elementType": "geometry.fill",
		"stylers": [
		{ "color": "#d3d3d3" }
		]
	},{
		"featureType": "transit",
		"stylers": [
		{ "color": "#808080" },
		{ "visibility": "off" }
		]
	},{
		"featureType": "road.highway",
		"elementType": "geometry.stroke",
		"stylers": [
		{ "visibility": "on" },
		{ "color": "#b3b3b3" }
		]
	},{
		"featureType": "road.highway",
		"elementType": "geometry.fill",
		"stylers": [
		{ "color": "#ffffff" }
		]
	},{
		"featureType": "road.local",
		"elementType": "geometry.fill",
		"stylers": [
		{ "visibility": "on" },
		{ "color": "#ffffff" },
		{ "weight": 1.8 }
		]
	},{
		"featureType": "road.local",
		"elementType": "geometry.stroke",
		"stylers": [
		{ "color": "#d7d7d7" }
		]
	},{
		"featureType": "poi",
		"elementType": "geometry.fill",
		"stylers": [
		{ "visibility": "on" },
		{ "color": "#ebebeb" }
		]
	},{
		"featureType": "administrative",
		"elementType": "geometry",
		"stylers": [
		{ "color": "#a7a7a7" }
		]
	},{
		"featureType": "road.arterial",
		"elementType": "geometry.fill",
		"stylers": [
		{ "color": "#ffffff" }
		]
	},{
		"featureType": "road.arterial",
		"elementType": "geometry.fill",
		"stylers": [
		{ "color": "#ffffff" }
		]
	},{
		"featureType": "landscape",
		"elementType": "geometry.fill",
		"stylers": [
		{ "visibility": "on" },
		{ "color": "#efefef" }
		]
	},{
		"featureType": "road",
		"elementType": "labels.text.fill",
		"stylers": [
		{ "color": "#696969" }
		]
	},{
		"featureType": "administrative",
		"elementType": "labels.text.fill",
		"stylers": [
		{ "visibility": "on" },
		{ "color": "#737373" }
		]
	},{
		"featureType": "poi",
		"elementType": "labels.icon",
		"stylers": [
		{ "visibility": "off" }
		]
	},{
		"featureType": "poi",
		"elementType": "labels",
		"stylers": [
		{ "visibility": "off" }
		]
	},{
		"featureType": "road.arterial",
		"elementType": "geometry.stroke",
		"stylers": [
		{ "color": "#d6d6d6" }
		]
	},{
		"featureType": "road",
		"elementType": "labels.icon",
		"stylers": [
		{ "visibility": "off" }
		]
	},{
	},{
		"featureType": "poi",
		"elementType": "geometry.fill",
		"stylers": [
		{ "color": "#dadada" }
		]
	}
	];

var init = function (map_selector, icon_selector){
	/*
	*  new_map
	*  This function will render a Google Map onto the selected jQuery element
	*  @type	function
	*  @date	8/11/2013
	*  @since	4.3.0
	*  @param	$el (jQuery element)
	*  @return	n/a
	*/

	function new_map( $el ) {
		var $markers = $el.find('.marker'),
			args = {
				zoom		: 15,
				center		: new google.maps.LatLng(0, 0),
				mapTypeId	: google.maps.MapTypeId.ROADMAP,
				disableDoubleClickZoom: true,
				mapTypeControl: false,
				streetViewControl: false,
				draggable : false,
				scrollwheel : false,
				overviewMapControl: false,
				overviewMapControlOptions: {
					opened: false,
				},
				styles: map_styles
			};

		// create map
		var map = new google.maps.Map( $el[0], args);

		// add a markers reference
		map.markers = [];

		// add markers
		$markers.each(function(){
			add_marker( $(this), map );
		});

		// center map
		center_map( map );

		// return
		return map;
	}

	/*
	*  add_marker
	*  This function will add a marker to the selected Google Map
	*  @type	function
	*  @date	8/11/2013
	*  @since	4.3.0
	*  @param	$marker (jQuery element)
	*  @param	map (Google Map object)
	*  @return	n/a
	*/

	function add_marker( $marker, map ) {
		// create marker
		var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') ),
			marker = new google.maps.Marker({
				icon: marker_icon,
				position: latlng,
				map: map
			});

		// add to array
		map.markers.push( marker );

		// if marker contains HTML, add it to an infoWindow
		if( $marker.html() ) {
			// create info window
			var infowindow = new google.maps.InfoWindow({
				content	: $marker.html(),
				maxWidth : 400
			});

			// show info window when marker is clicked
			google.maps.event.addListener(marker, 'click', function() {
				infowindow.open( map, marker );

				// Track last opened window
				if (opened_infoWindow === null) {
					opened_infoWindow = infowindow;
				} else {
					opened_infoWindow.close();

					if (opened_infoWindow == infowindow) {
						opened_infoWindow = null;
					} else {
						opened_infoWindow = infowindow;
					}
				}

			});
		}

	}

	/*
	*  center_map
	*  This function will center the map, showing all markers attached to this map
	*  @type	function
	*  @date	8/11/2013
	*  @since	4.3.0
	*  @param	map (Google Map object)
	*  @return	n/a
	*/

	function center_map( map ) {
		var bounds = new google.maps.LatLngBounds();

		// loop through all markers and create bounds
		$.each( map.markers, function( i, marker ){
			var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

			bounds.extend( latlng );
		});

		// only 1 marker?
		if( map.markers.length == 1 ) {
			// set center of map
			map.setCenter( bounds.getCenter() );
			// map.setZoom( 10 );
		} else {
			// fit to bounds
			map.fitBounds( bounds );
		}

	}

	/*
	*  document ready
	*  This function will render each map when the document is ready (page has loaded)
	*  @type	function
	*  @date	8/11/2013
	*  @since	5.0.0
	*  @param	n/a
	*  @return	n/a
	*/
	// global var
	var map = null,
		opened_infoWindow = null,
		marker_icon = $(icon_selector).data("icon");

	$(map_selector).each(function(){
		// create map
		map = new_map( $(this) );
	});
};


module.exports ={
	init : init
};
},{}],5:[function(require,module,exports){
// ==============================
// Existence checker
// ==============================
$.fn.exists = function(){
	return this.length > 0;
};


// ==============================
// Check browser scrollbar width
// ==============================
var getScrollbarWidth = function (){
	var style = {"max-height":"100px", "overflow":"scroll", "position":"absolute"},
		wrapper = $("<div id='scroll_bar_check_A'></div>").css(style),
		inner = $("<div id='scroll_bar_check_B'></div>"),
		stretcher = $("<img src='/assets/img/force-scroll.png'/>"),
		scrollBarWidth = 0;

	wrapper.append(stretcher).append(inner);
	$("body").append(wrapper);

	scrollBarWidth = wrapper.width()-inner.width();
	wrapper.remove();

	return scrollBarWidth;
};



// ==============================
// Check IE version
// ==============================
var ieVersion = function () {
	var ua = window.navigator.userAgent;

	var msie = ua.indexOf("MSIE ");
	if (msie > 0) {
		// IE 10 or older => return version number
		return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
	}

	var trident = ua.indexOf("Trident/");
	if (trident > 0) {
		// IE 11 => return version number
		var rv = ua.indexOf("rv:");
		return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
	}

	var edge = ua.indexOf("Edge/");
	if (edge > 0) {
		// Edge (IE 12+) => return version number
		return parseInt(ua.substring(edge + 5, ua.indexOf(".", edge)), 10);
	}

	// other browser
	return false;
};


var equalHeights = function() {
	var maxHeight = 0,
	$this = $(this);

	$this.each( function() {
		var height = $(this).innerHeight();

		if ( height > maxHeight ) { maxHeight = height; }
	});

	return $this.css('height', maxHeight);
};


var ajustHeights = function(selector){
	var elements = $(selector);

	$.fn.ajustHeight = equalHeights;
	elements.css({height:"auto"}).ajustHeight();
};

module.exports = {
	getScrollbarWidth : getScrollbarWidth,
	ieVersion : ieVersion,
	equalHeights : equalHeights,
	ajustHeights : ajustHeights
};
},{}],6:[function(require,module,exports){
// ==========================================
// Preloader with percentage by image count
// ==========================================
var	preloader_container = $("#preloader"),
	hasImageProperties = ["background", "backgroundImage", "listStyleImage", "borderImage", "borderCornerImage", "cursor"],
	hasImageAttributes = ["srcset"],
	match_url = /url\(\s*(['"]?)(.*?)\1\s*\)/g,
	all_images = [],
	total = 0,
	count = 0,
	load = $.Deferred();

module.exports = function preloader() {

/*	function img_loaded(){
		count ++;
		if(count === total){
			return done_loading();
		}
	}*/

	function done_loading(){
		load.resolve();
		preloader_container.delay(400).fadeOut(400, function(){
			// Callbacks
		});
	}

/*	function images_loop (total) {
		for(var i=0; i<total; i++){
			var test_image = new Image();

			test_image.onload = img_loaded;
			test_image.onerror = img_loaded;

			if (all_images[i].srcset) {
				test_image.srcset = all_images[i].srcset;
			}

			test_image.src = all_images[i].src;
		}
	}

	// Get all images
	$("*").each(function () {
		var element = $(this);

		if (element.is("img") && element.attr("src")) {
			all_images.push({
				src: element.attr("src"),
				element: element[0]
			});
		}

		$.each(hasImageProperties, function (i, property) {
			var propertyValue = element.css(property);
			var match;

			if (!propertyValue) {
				return true;
			}

			match = match_url.exec(propertyValue);
			
			if (match) {
				all_images.push({
					src: match[2],
					element: element[0]
				});
			}
		});

		$.each(hasImageAttributes, function (i, attribute) {
			var attributeValue = element.attr(attribute);

			if (!attributeValue) {
				return true;
			}

			all_images.push({
				src: element.attr("src"),
				srcset: element.attr("srcset"),
				element: element[0]
			});
		});
	});

	total = all_images.length;*/

	// Start preloader or exit
/*	if (total === 0) {
		done_loading();
	} else {
		images_loop(total);
	}*/

	setTimeout(function(){
		done_loading();
	}, 1000);

	return load.promise();
};
},{}],7:[function(require,module,exports){
function init(){
	var animation_time = 600,
		gallery = $("#products-gallery"),
		controls_block = $("#products-gallery__controls"),
		controls = controls_block.find("li"),
		items_block = $("#products-gallery__items"),
		items = items_block.find("li"),
		cursor_area = $("#cursor-area"),
		cursorPlus = $("#cursor-plus");

	cursor_area.attr("href", controls.filter(".active").data("link"));

	// CHANGE ITEMS
	controls.on("click", function(){
		var clicked = $(this),
			index = clicked.index(),
			item = items.eq(index);

		clicked.add(item)
			.addClass("active")
			.siblings()
			.removeClass("active");

		cursor_area.attr("href", clicked.data("link"));
	});


	// CURSOR TRACKING
	gallery.mousemove(function(e) {
		cursorPlus.offset({
			left: e.pageX-60,
			top: e.pageY-60
		});

	});

	// SHOW PLUS PIC ON GALLERY ITEM HOVER
	cursor_area.hover(function(e){
			cursorPlus.addClass("active");
		}, function(e){
			cursorPlus.removeClass("active");
		});

}

module.exports = {
	init : init
};
},{}],8:[function(require,module,exports){
var backPanel = $("#bg-panel"),
	screens_selector,
	screens,
	pagination = $("#screen-pager"),
	screen_counter,
	screen_navigation,
	is_animated;

// =================================
// Counter
// =================================
function counter(countedElements_selector, current_selector, total_selector, remove_delay){
	var current = $(current_selector),
		total = $(total_selector),
		elements = $(countedElements_selector),
		delay = remove_delay || 700;

	current.html("<span>1</span>");
	total.html(elements.length);

	var update = function(number){
		var new_number = $("<span class='bb-animation fade-in'>"+ number +"</span>"),
			current_number = current.find("span");

		if ( number != parseInt(current_number.text()) ) {
			current_number.removeClass("fade-in")
				.addClass("bb-animation fade-out removing")
				.css({
					"position" : "absolute"
				});

			new_number.appendTo(current);

			setTimeout(function(){
				current.find(".removing").remove();
			}, delay);
		}
	};

	return {
		current : current,
		total : total,
		update : update
	};
}


// =================================
// Process screen animations
// =================================
var processScreen = function (state, animation_class) {
	var screen = $(this),
		selector = animation_class || ".bb-animation",
		animated_elements = screen.find(selector),
		panel_state = screen.data("bg-state");

	animated_elements.map(function(){
		var element = $(this),
			animation_in = element.data("animation-in"),
			animation_out = element.data("animation-out");

		if(state === "load"){
			element.addClass(animation_in)
				.removeClass(animation_out);
		} else {
			element.addClass(animation_out)
				.removeClass(animation_in);
		}
	});

	
	if(panel_state){
		if(panel_state=="hideUp"){
			backPanel.addClass(panel_state);
		} else {
			backPanel.removeClass()
				.addClass(panel_state);
		}
	}

	return this;
};


// =================================
// Process screen shorthands
// =================================
var loadScreen = function(){
	$(this).processScreen("load");
	return this;
};

var leaveScreen = function(){
	$(this).processScreen("leave");
	return this;
};



// =================================
// Change screens on scroll
// =================================
function showScreen(which_one, process_navigation, scroll_delay){
	var screenToShow,
		show_index,
		activeScreen = screens.filter(".active"),
		nextScreen = activeScreen.next(screens_selector),
		prevScreen = activeScreen.prev(screens_selector),
		delay = scroll_delay || 700;

	if(!is_animated){
		is_animated = true;

		setTimeout(function(){
			is_animated = false;
		}, delay);

		if(which_one === "next"){
			screenToShow = nextScreen.exists() ? nextScreen : screens.eq(0);
		} else if (which_one === "prev") {
			screenToShow = prevScreen.exists() ? prevScreen : screens.eq(-1);
		} else {
			screenToShow = screens.eq(which_one);
		}

		activeScreen.leaveScreen().removeClass("active");
		screenToShow.addClass("active").loadScreen();

		show_index = screens.index(screenToShow) + 1;
		screen_counter.update(show_index);

		if(process_navigation === true){
			screen_navigation.eq(show_index-1)
				.addClass("active")
				.siblings()
				.removeClass("active");
		}
	}

}

function init(selector) {
	var navigation_existence = false;

	if($("#screen-navigation").exists()){
		navigation_existence = true;
		screen_navigation = $("#screen-navigation").find("li");

		screen_navigation.on("click", function(){
			showScreen($(this).index(), true);
		});
	}

	$.fn.processScreen = processScreen;
	$.fn.loadScreen = loadScreen;
	$.fn.leaveScreen = leaveScreen;

	screens_selector = selector || ".bb-change";
	screens = $(screens_selector);

	if(screens.length > 1){
		screen_counter = counter(screens, "#current-page", "#total-pages");

		$(document).on('mousewheel', function(event) {
			if (event.deltaY < 0){
				showScreen("next", navigation_existence);
			} else {
				showScreen("prev", navigation_existence);
			}
		});

		$(document).swipe({
			fallbackToMouseEvents: false,
			excludedElements: ".feedback__input, .contacts-map",
			swipeRight: function(event, distance, duration, fingerCount, fingerData, currentDirection){
				showScreen("prev", navigation_existence);
			},
			swipeLeft: function(event, distance, duration, fingerCount, fingerData, currentDirection){
				showScreen("next", navigation_existence);
			}
		});

	} else {
		pagination.remove();
	}
}

module.exports = {
	init : init,
	showScreen : showScreen,
	counter : counter
};
},{}],9:[function(require,module,exports){
// ==============================
// Simple tabs
// ==============================

module.exports = function(control_selector, content_selector){
	$(control_selector).on("click", function(){
		var index = $(this).index(),
			content = $(content_selector);

		content.eq(index)
			.add(this)
			.addClass("active")
			.siblings()
			.removeClass("active");
	});
};
},{}],10:[function(require,module,exports){
// ==============================
// Globals
// ==============================
var initial_screen = window.location.hash || "#home",
	$screen = $(initial_screen);

var $casesbox = $(".cases"),
	$cases = $(".case"),
	cases_total = $cases.length,
	$cases_controls = $("#cases-controls li"),
	$cases_titles = $(".case__title"),
	$preview_controls = $(".preview-control"),
	$preview_controls_next = $(".preview-control_next"),
	$preview_controls_prev = $(".preview-control_prev"),
	$case_nav = $(".case-control"),
	$case_nav_next = $(".case-control_next"),
	$case_nav_prev = $(".case-control_prev"),
	$slides_nav = $(".slider-control"),
	$slides_nav_next = $(".slider-control_next"),
	$slides_nav_prev = $(".slider-control_prev"),
	$thumbs_nav = $(".thumbs-control"),
	$case_screens,
	$active_case;

var	$heropanel = $(".hero-panel"),
	$hero_controls = $(".hero-control"),
	$hero_controls_next = $hero_controls.filter(".hero-control_next"),
	$hero_controls_prev = $hero_controls.filter(".hero-control_prev"),
	$hero_items_box = $(".hero-panel__items"),
	$hero_items = $hero_items_box.find(".hero-panel__item");

var	$menu_button = $(".menu-toggle"),
	$menu = $(".menu"),
	$menu_links = $(".menu__items>li>a"),
	$screen_links = $(".screen-link");

var $support_button = $("#support-toggle"),
	$quotes_button = $("#quotes-toggle"),
	$support_screen = $("#support"),
	$quotes_screen = $("#quotes");

// ==============================
// Support and Quotes
// ==============================
function _support(){
	$quotes_button.on("click", function(){
		$quotes_screen.toggleClass("active");

		if ($quotes_screen.hasClass("active")) {
			if ($screen[0].id != "home" && $screen[0].id != "cases"){
				$screen.addClass("hidden");
			}
			$casesbox.addClass("faded");

			if ($support_screen.hasClass("active")) {
				$support_screen.removeClass("active");
			}
		} else {
			if ($screen[0].id != "home") {
				$screen.removeClass("hidden");
			}
			$casesbox.removeClass("faded");
		}
	});

	$support_button.on("click", function(){
		$support_screen.toggleClass("active");
		if ($support_screen.hasClass("active")) {
			if ($screen[0].id != "home" && $screen[0].id != "cases"){
				$screen.addClass("hidden");
			}
			$casesbox.addClass("faded");

			if ($quotes_screen.hasClass("active")) {
				$quotes_screen.removeClass("active");
			}
		} else {
			if ($screen[0].id != "home") {
				$screen.removeClass("hidden");
			}
			$casesbox.removeClass("faded");
		}
	});
}

function _closeQuotes(){
	$quotes_screen.removeClass("active");
	if ($screen[0].id != "home") {
		$screen.removeClass("hidden");
	}
	$casesbox.removeClass("faded");
}

function _closeSupport(){
	$support_screen.removeClass("active");
	if ($screen[0].id != "home") {
		$screen.removeClass("hidden");
	}
	$casesbox.removeClass("faded");
}


// ==============================
// Services popup
// ==============================
function _services_popup(){
	var $popup_toggle = $("#services-popup-toggle"),
		$services_popup = $("#services-popup"),
		$services_items = $services_popup.find(".services-popup__list-item"),
		$services_input = $("#services-input"),
		$services_submit = $("#services-submit"),
		$selected_services_inp = $("#services-selected"),
		$quotes_form = $("#quotes-form"),
		$quotes_willhide = $quotes_screen.find(".form__label, .form__submit, .quotes__title").not(".donothide"),
		selected_services = "",
		toggle_text = "";

		$services_input.css({
			"display":"none"
		});

		$services_input.prop("disabled", true);
		$services_submit.prop("disabled", true);

		$popup_toggle.on("click", function(){
			if ($services_popup.hasClass("active")) {
				selected_services = "";

				if ($services_items.filter(".active").exists()) {
					toggle_text = "Change items";

					$services_items.filter(".active").each(function(){
						if (selected_services === "") {
							selected_services = $(this).text();
						} else {
							selected_services += ", " + $(this).text();
						}
					});

				} else {
					toggle_text = "Select items";
				}

				if (selected_services !== "") {
					$selected_services_inp.val(selected_services);
					$services_input.val(selected_services)
						.css({
							"display" : "block",
							"visibility" : "visible",
						});

					$services_submit.prop("disabled", false)
						.removeClass("disabled");
				} else {
					$selected_services_inp.val(selected_services);
					$services_input.val(selected_services)
						.css({
							"display" : "none",
							"visibility" : "hidden",
						});

					$services_submit.prop("disabled", true)
						.addClass("disabled");
				}

			} else {
				toggle_text = "Apply items";

				if ($services_input.val() !== "") {
					$services_input.css({
						"visibility":"hidden"
					});
				}

			}


			$services_popup.toggleClass("active");
			$quotes_willhide.toggleClass("hidden");

			$popup_toggle.fadeOut(250, function(){
					$popup_toggle.text(toggle_text)
					.fadeIn();
				});

		});

		$services_items.on("click", function(){
			var $clicked = $(this);

			$clicked.toggleClass("active");
			// selected_services += $clicked.text();
		});
}

// ==============================
// Menu handlers
// ==============================
function closeMenu() {
	$menu_button.addClass("close").removeClass("open");
	$menu.removeClass("active");
}

function openMenu() {
	$menu_button.addClass("open").removeClass("close");
	$menu.addClass("active");
}

function _menu() {
	var $initial_link = $menu_links.filter('[href$="'+initial_screen+'"]');
	console.log(initial_screen);
	console.log($initial_link);

	// Toggle menu state
	$menu_button.on("click", function(){
		if ($menu_button.hasClass("open")) {
			closeMenu();
		} else {
			openMenu();
		}
	});

	// Open page
	$screen_links.on("click", function(e){
		var $clicked = $(this),
			href = $clicked.attr("href"),
			$menu_link = $menu_links.filter('[href="'+href+'"]');

		if($menu_link.exists()){
			$menu_link.trigger("click");
		}
	});

	$menu_links.on("click", function(e){
		var $clicked = $(this),
			href_reg = /(#[a-zA-Z-_]+)/g,
			clicked_href = $clicked.attr("href"),
			reg_result = clicked_href.match(href_reg),
			screen_id = reg_result ? reg_result[0] : '#home';

		if ($(screen_id).exists() && screen_id[0]=="#") {
			// e.preventDefault();

			if ($quotes_screen.hasClass("active")) {
				_closeQuotes();
			}

			if ($support_screen.hasClass("active")) {
				_closeSupport();
			}

			$screen = $(screen_id);

			// $menu_links
			// 	.filter('[href="'+screen_id+'"]')
			// 	.parent()
			// 	.addClass("active")
			// 	.siblings()
			// 	.removeClass("active");

			$clicked.parent()
				.addClass("active")
				.siblings()
				.removeClass("active");


			$screen.addClass("active")
				.siblings()
				.removeClass("active");

			if (screen_id == "#home" && $cases.filter(".active").exists()) {
				var $showed_case = $cases.filter(".active");

				$cases.scrollTop(0)
					.filter(".active")
					.addClass("preview")
					.removeClass("active");

				$heropanel.removeClass("hidden");

				$hero_controls
					.not(".disabled")
					.addClass("show");

				$case_nav.add($slides_nav)
					.removeClass("show")
					.prop("disabled", true);

				$thumbs_nav
					.removeClass("show")
					.prop("disabled", true);


				if (cases_total > 1) {
					var case_index = $cases.index($showed_case);

					if (case_index == cases_total-1 || case_index === 0) {
						var disable_index = case_index/(cases_total-1),
							enable_index = 1 - disable_index;

						$preview_controls.eq(disable_index)
							.prop("disabled", true)
							.addClass("disabled")
							.removeClass("show");

						$preview_controls.eq(enable_index)
							.prop("disabled", false)
							.addClass("show")
							.removeClass("disabled");
					} else {
						$preview_controls
							.prop("disabled", false)
							.addClass("show")
							.removeClass("disabled");
					}
				}
			}

			if (screen_id == "#cases" && $screen.hasClass("hidden")) {
				$screen.removeClass("hidden");
			}

			closeMenu();
		}
	});

	$initial_link.trigger("click");
}


// ==============================
// Process items change
// ==============================
function get_next($clicked, parent_class, el_class, cur_class, nav_sibling_class, nav_next_class, delayed) {
	parent_class = "." + parent_class;
	el_class = "." + el_class;
	cur_class = "." + cur_class;
	nav_sibling_class = "." + nav_sibling_class;

	var $current = $(parent_class).find(el_class).filter(cur_class),
		$clicked_button = $clicked,
		$other_button = $clicked_button.siblings(nav_sibling_class),
		$next,
		index,
		is_more;

	if ($clicked_button.hasClass(nav_next_class)){
		$next = $current.next(el_class);
		is_more = $next.next(el_class).exists();
	} else {
		$next = $current.prev(el_class);
		is_more = $next.prev(el_class).exists();
	}

	if (!is_more) {
		$clicked_button.prop("disabled", true)
			.addClass("disabled")
			.removeClass("show");
	} else if (delayed) {
		$clicked_button.add($other_button)
			.prop("disabled", true);

		setTimeout(function(){
			$clicked_button.add($other_button)
				.prop("disabled", false);
		},1000);
	}

	$other_button.prop("disabled", false)
		.addClass("show")
		.removeClass("disabled");

	return $next;
}


// ==============================
// Hero slider
// ==============================
function _hero() {
	$hero_items.eq(0).addClass("active");

	if ($hero_items.length > 1) {
		var is_animated = false;

		// Set controls
		$hero_controls.eq(0)
			.prop("disabled", true)
			.addClass("disabled");

		$hero_controls.eq(1).addClass("show");

		// Change hero item
		$hero_controls.on("click", function(){
			var $clicked = $(this),
				$next,
				index,
				bgclass;

			if(!is_animated){
				is_animated = true;

				setTimeout(function(){
					is_animated = false;
				}, 1000);

				$next = get_next($clicked, "hero-panel", "hero-panel__item", "active", "hero-control", "hero-control_next", false);

				index = $next.index();
				bgclass = $next.data("bg");

				$hero_items_box.css({
					"top" : -(index * 100) + "%"
				});

				$heropanel.attr("class", "hero-panel " + bgclass);

				$next.addClass("active")
					.siblings()
					.removeClass("active");
			}
		});

		$heropanel.on('mousewheel', function(event) {
			if ( event.deltaY < 0 && $hero_controls_next.prop("disabled") !== true ){
				$hero_controls_next.trigger("click");
			} else if ( event.deltaY > 0 && $hero_controls_prev.prop("disabled") !== true ) {
				$hero_controls_prev.trigger("click");
			}
		});

		$heropanel.swipe({
			fallbackToMouseEvents: false,
			swipeUp: function(event, distance, duration, fingerCount, fingerData, currentDirection){
				if ($hero_controls_next.prop("disabled") !== true){
					$hero_controls_next.trigger("click");
				}
			},
			swipeDown: function(event, distance, duration, fingerCount, fingerData, currentDirection){
				if ($hero_controls_prev.prop("disabled") !== true){
					$hero_controls_prev.trigger("click");
				}
			}
		});
	}
}


// ==============================
// Cases handlers
// ==============================
function activate_case($case){
	$active_case = $case;
	$case_screens = $active_case.find(".case__screen");

	$active_case.scrollTop(0)
		.addClass("active")
		.removeClass("preview")
		.siblings()
		.removeClass("active preview");

	$heropanel.addClass("hidden");

	$preview_controls
		.add($hero_controls)
		.add($slides_nav)
		.removeClass("show");

	$case_screens.eq(0)
		.addClass("active")
		.siblings()
		.removeClass("active");

	if ($case_screens.length > 1) {
		$case_nav.eq(0)
			.removeClass("show")
			.prop("disabled", true);
		$case_nav.eq(1).addClass("show")
			.prop("disabled", false);
	} else {
		$case_nav.removeClass("show")
			.prop("disabled", true);
	}

	if (cases_total > 1) {
		// Set preview controls
		$thumbs_nav
			.prop("disabled", false)
			.addClass("show");
	}
}

function _cases() {
	var is_animated = false;

	$cases.eq(0).addClass("preview");


	// CASES LIST CONTROLS
	$cases_controls.on("click", function(){
		var $clicked = $(this),
			index = $clicked.index(),
			$case = $cases.eq(index);

		activate_case($case);
		$screen.addClass("hidden");

	});


	// CASES BIG TITLES CLICK
	$cases_titles.on("click", function(){
		var $clicked = $(this),
			$case = $clicked.closest(".case");

		activate_case($case);
	});


	// SINGLE CASE NAVIGATION
	$case_nav.on("click", function(){
		var $clicked = $(this),
			// $current_case = $cases.filter(".active"),
			// screen_height = $current_case.find(".case__screen").height(),
			screen_height = $active_case.find(".case__screen").height(),
			$next,
			scroll_amount;

		if(!is_animated){
			$next = get_next($clicked, "case.active", "case__screen", "active", "case-control", "case-control_next", true);

			index = $next.index();
			scroll_amount = screen_height * index;

			$active_case.animate({
				scrollTop: scroll_amount
			}, 700, "swing");

			$next.addClass("active")
				.siblings()
				.removeClass("active");

			if ($next.find(".slider__slide").length > 1) {
				$next.find(".slider__slide").eq(0)
					.addClass("active")
					.siblings()
					.removeClass("active");

				$slides_nav.eq(0)
					.removeClass("show")
					.prop("disabled", true);
				$slides_nav.eq(1)
					.addClass("show")
					.prop("disabled", false);
			} else {
				$next.find(".slider__slide").eq(0)
					.addClass("active")
					.siblings()
					.removeClass("active");

				$slides_nav.removeClass("show")
					.prop("disabled", true);
			}

			is_animated = true;

			setTimeout(function(){
				is_animated = false;
			}, 1000);
		}
	});

	$cases.on('mousewheel', function(event) {
		if ( event.deltaY < 0 
			&& $case_nav_next.prop("disabled") !== true
			&& $case_nav_next.hasClass("show") ){
			$case_nav_next.trigger("click");
		} else if ( event.deltaY > 0 
			&& $case_nav_prev.prop("disabled") !== true
			&& $case_nav_prev.hasClass("show") ) {
			$case_nav_prev.trigger("click");
		}
	});

	$cases.swipe({
		fallbackToMouseEvents: false,
		swipeUp: function(event, distance, duration, fingerCount, fingerData, currentDirection){
			if ($case_nav_next.prop("disabled") !== true
				&& $case_nav_next.hasClass("show")){
				$case_nav_next.trigger("click");
			}
		},
		swipeDown: function(event, distance, duration, fingerCount, fingerData, currentDirection){
			if ($case_nav_prev.prop("disabled") !== true
				&& $case_nav_prev.hasClass("show")){
				$case_nav_prev.trigger("click");
			}
		},
		swipeLeft: function(event, distance, duration, fingerCount, fingerData, currentDirection){
			if ($slides_nav_next.prop("disabled") !== true
				&& $slides_nav_next.hasClass("show")){
				$slides_nav_next.trigger("click");
			}
		},
		swipeRight: function(event, distance, duration, fingerCount, fingerData, currentDirection){
			if ($slides_nav_prev.prop("disabled") !== true
				&& $slides_nav_prev.hasClass("show")){
				$slides_nav_prev.trigger("click");
			}
		}
	});

	// CASE SLIDES NAVIGATION
	$slides_nav.on("click", function(){
		var $clicked = $(this),
			$next;

		$next = get_next($clicked, "case__screen.active", "slider__slide", "active", "slider-control", "slider-control_next", true);
		$next.addClass("active")
			.siblings()
			.removeClass("active");
	});



	// CASES PREVIEW CONTROLS
	if (cases_total > 1) {
		// Set preview controls
		$preview_controls.eq(0)
			.prop("disabled", true)
			.addClass("disabled");

		$preview_controls.eq(1).addClass("show");

		// Change preview
		$preview_controls.on("click", function(){
			if(!is_animated){
				var $clicked = $(this),
					$current_case = $cases.filter(".preview"),
					$next;

				$next = get_next($clicked, "cases", "case", "preview", "preview-control", "preview-control_next", true);

				$next.addClass("preview")
					.siblings()
					.removeClass("preview");

				is_animated = true;

				setTimeout(function(){
					is_animated = false;
				}, 1000);
			}
		});

		// Change case through thumbs
		$thumbs_nav.on("click", function(){
			if(!is_animated){
				var $clicked = $(this),
					case_index = $active_case.index(),
					next_index = (case_index+1 == cases_total) ? 0 : case_index + 1,
					show_index = (case_index-1 < 0) ? ($cases.length-1) : case_index - 1,
					$case;

				if($clicked.hasClass("thumbs-control_next")){
					show_index = next_index;
				}

				$case = $cases.eq(show_index);

				$active_case
					.removeClass("active")
					.animate({scrollTop:0},700);

				activate_case($case);

				is_animated = true;

				setTimeout(function(){
					is_animated = false;
				}, 1000);
			}
		});

		$casesbox.on('mousewheel', function(event) {
			if ( event.deltaY < 0 
				&& $preview_controls_next.prop("disabled") !== true
				&& $preview_controls_next.hasClass("show") ){
				$preview_controls_next.trigger("click");
			} else if ( event.deltaY > 0 
				&& $preview_controls_prev.prop("disabled") !== true
				&& $preview_controls_prev.hasClass("show") ) {
				$preview_controls_prev.trigger("click");
			}
		});

		$casesbox.swipe({
			fallbackToMouseEvents: false,
			swipeLeft: function(event, distance, duration, fingerCount, fingerData, currentDirection){
				if ($preview_controls_next.prop("disabled") !== true
					&& $preview_controls_next.hasClass("show") ){
					$preview_controls_next.trigger("click");
				}
			},
			swipeRight: function(event, distance, duration, fingerCount, fingerData, currentDirection){
				if ($preview_controls_prev.prop("disabled") !== true
					&& $preview_controls_prev.hasClass("show") ) {
					$preview_controls_prev.trigger("click");
				}
			}
		});
	}
}

function init() {
	_menu();
	_hero();
	_cases();
	_support();
	_services_popup();
}

module.exports = {
	init : init
};
},{}]},{},[1]);
